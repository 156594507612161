.page-payment{
  margin-top: 60px;
}

.failure-block{
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;

  .failure-msg{
    text-align: center;

    i{
      margin-bottom: 40px;
      font-size: 48px;
      color: $color-red-lightest;
    }
    h5{
      font-size: 18px;
      font-weight: 300;
    }
  }

}

.page-success{
  margin-top: 60px;
  background-color: $color-silver-light;
}


.success-block{
  min-height: 300px;

  .success-msg{
    text-align: center;
    margin: 20px 0;

    i{
      margin-bottom: 20px;
      font-size: 38px;
      color: $color-green-lighter;
    }
    h5{
      font-size: 18px;
      font-weight: 300;
    }
  }

  .traveler-data-item{
    display: block;
    
  }

}
