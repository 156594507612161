// COMPONENT: MODALS
// -----------------------------------------------------------------------------

// Actual modal
.modal-content {
  border: 1px solid $color-silver;
  background-clip: border-box;
  box-shadow: 0 3px 9px rgba(0,0,0,.25)
}

// Modal background
.modal-backdrop {
  background-color: $modal-backdrop-bg;

  // Fade for backdrop
  &.fade { opacity: 0; }
  &.show { opacity: $modal-backdrop-opacity; }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  height: 60px;
  padding: 0;
  background-color: $color-silver-lighter;

  .close {
    padding: 14px;
    // auto on the left force icon to the right even when there is no .modal-title
    margin: 0 0 -14px auto;
  }
}

// Icon aside title text within header
.modal-icon{
  margin-top: 10px;
  width: 60px;
  height: 40px;
  display: inline-block;
  float: left;
  border-right: 1px solid #e8e8e8;
  text-align: center;
  line-height: 40px;

  svg{
    margin-top: 8px;
    fill: #606c76;
    width: 100%;
    height: 24px;
  }
}

// Title text within header
.modal-title {
  margin: 0 0 0 14px;
  line-height: 60px;
  font-size: 18px;
  font-weight: 400;
  color: $color-grey;

  b{
    margin-left: 20px;
    font-weight: 500;
    font-size: 18px;
  }

}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  // Enable `flex-grow: 1` so that the body take up as much space as possible
  // when should there be a fixed height on `.modal-dialog`.
  flex: 1 1 auto;
  padding: $modal-inner-padding;
}

// Footer (for actions)
.modal-footer {
  display: flex;
  align-items: center; // vertically center
  justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
  //padding: $modal-inner-padding;
  border-top: $modal-footer-border-width solid $modal-footer-border-color;

  height: 60px;
  padding: 0;

  // Easily place margin between footer elements
  > :not(:first-child) { margin-left: .25rem; }
  > :not(:last-child) { margin-right: .25rem; }

  /*
  .btn+.btn {
    margin: 0;
    margin-left: -4px;
    border-left: 1px solid #e8e8e8;
  }
  */

  .btn{
    font-size: 16px;
    font-weight: 400;
    color: $color-grey;
    padding: 0 20px;
    background: none;
    border: none;
    outline: 0;


    i{
      margin-right: 6px;
      display: none;
      align-items: center;
    }

    span{

    }

    &:focus{
      box-shadow: none;
    }

    &:hover{
      color: $color-grey-darkest;
    }

    &.active{
      i{ display: inline-flex; }
    }
  }

  .btn-save{
    color: $color-green;

    &:hover{
      color: $color-green-darker;
    }
  }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// Scale up the modal
@include media-breakpoint-up(sm) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    max-width: $modal-md;
    margin: $modal-dialog-margin-y-sm-up auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - (#{$modal-dialog-margin-y-sm-up} * 2));
  }

  .modal-content {
    @include box-shadow($modal-content-box-shadow-sm-up);
  }

  .modal-sm { max-width: $modal-sm; }

}

@include media-breakpoint-up(lg) {
  .modal-lg { max-width: $modal-lg; }
}


// MODAL SIZES
.modal.modal-small{
  .modal-dialog{ max-width: 380px; }
}

.modal.modal-medium{
  .modal-dialog{ max-width: 640px; }
}

.modal.modal-large{
  .modal-dialog{ max-width: 840px; }
}

.modal.modal-xlarge{
  .modal-dialog{ max-width: 960px; }
}



// CUSTOM ------------------------


.modal-status{
  margin-left: 20px;
  i{ color: $color-grey; }
  &.success i{ color: $color-green; }
}

.modal-spinner .modal-body{ min-height: 180px; }
.modal-spinner-container{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color-white, .85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $zindex-modal + 1;
}




.modal-fluid{

  .modal-dialog{
    position: absolute;
    top: 10%;
    right: 10%;
    bottom: 10%;
    left: 10%;
    width: auto;
    margin: 0;
  }

  .modal-content{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .modal-header{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
  }

  .modal-body{
    padding: 0;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 60px;
    overflow: auto;
  }

  .modal-footer{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    padding: 0;
  }

}

.modal-simple{
  .modal-body{
    bottom: 0;
  }
}

.modal.modal-fluid-md{
  .modal-dialog{
    top: 16%;
    left: 16%;
    right: 16%;
    bottom: 16%;
  }
}





.modal-options{
  padding: 12px 15px;

  a, button{
    width: 60px;
    height: 36px;
    padding: 10px 30px;
    border-radius: 1px;
    background-color: $color-silver-lightest;
    border: 1px solid $color-silver-dark;
    padding: 0;
    outline: none;
    transition: all .25s;
    float: left;
    margin-right: 15px;
    border-radius: 18px;
    text-align: center;

    i{
      font-size: 14px;
      color: $color-grey;
      line-height: 36px;
    }

    &:hover{
      background-color: $color-blue;
      border-color: $color-blue;
      i{ color: #fff; }
    }
  }

  button.btn-danger:hover{
    background-color: $color-red-lightest;
    border-color: $color-red-lightest;
    i{ color: #fff; }
  }

  button.btn-success:hover{
    background-color: $color-green;
    border-color: $color-green;
    i{ color: #fff; }
  }
}

.modal-header .modal-search{
  position: absolute;
  top: 0;
  right: 70px;
  height: 60px;

  .search-icon{
    position: absolute;
    top: 10px;
    right: 0;
    width: 60px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    text-align: center;
    color: $color-silver-dark;
  }

  input{
    width: 200px;
    border: none;
    border: 1px solid $color-silver-lighter;
    padding: 0 50px 0 20px;
    margin: 10px 0 0 0;
    height: 40px;
    border-radius: 20px;
    line-height: 40px;
    font-weight: 300;
    font-size: 15px;
    color: $color-grey;
    background: #fff;
    transition: all .25s;

    &:valid{ width: 320px; }
    &:hover{ background: rgba($color-silver-lightest, .5); }
    &:focus{
      background: rgba($color-silver-lightest, .5);
      border-color: $color-silver-dark;
      width: 320px;
    }

  }
}

.modal.modal-tabs{

  form .modal-body{
    padding: 0;
  }

  .nav-tabs{
    height: 60px;
    padding: 20px 20px 0 20px;
    background: $color-brand-secondary;
    border: none;

    li{ margin: 0; }

    li a{
      height: 40px;
      padding: 0 30px;
      font-size: 15px;
      line-height: 40px;
      font-weight: 300;
      margin-right: 10px;
      color: $color-silver-light;
      position: relative;
      border: none;
    }

    li a i{ margin-right: 10px; }

    li a:hover{
      background-color: $color-grey;
      color: $color-brand-primary;
      border: none;
    }

    li.active a{
      color: $color-grey;
      border: none;
    }

    li.active a:hover{ background-color: #fff; }
  }

  .tab-content{ padding: 0; }

  .tab-content .tab-wrapper{
    position: relative;
    width: 100%;
  }

  .tab-content .tab-padding{ padding: 16px 20px; }

}


.modal-header-options{
  float: right;
  height: 60px;
  margin-right: 60px;
}

.modal-loading{
  width: 100%;
  height: 100%;
  position: relative;
  .modal-spinner{
    position: absolute;
    width: 260px;
    height: 120px;
    top: 50%;
    left: 50%;
    margin-left: -130px;
    margin-top: -60px;
    text-align: center;

    .sp-icon{
      width: 100%;
      color: $color-brand-primary;
      margin-bottom: 10px;
    }

    span{

      font-weight: 300;
      font-size: 16px;
    }
  }



}
