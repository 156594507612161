.mbc-navbar{
  background-color: $color-brand-primary;


  .navbar-brand{
    padding: 7px 0;
    svg{
      height: 26px;
      fill: #fff;
    }
  }

  .navbar-nav{
    text-transform: uppercase;

    .nav-link{
      font-size: 14px;
      @include media-breakpoint-up(lg) {
        margin-left: 20px;
      }
    }
  }
}


.mbc-carousel{
  background-color: #212121;

  .carousel-item {
    margin-top: 60px;
    //height: 45vh;
    height: 260px;
    min-height: 260px;
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cc-prev-icon,
  .cc-next-icon{
    background: rgba(#fff, .25);
    margin-top: 50px;
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    border: 1px solid rgba(#fff, .35);
    text-align: center;
    opacity: 0;
    transition: opacity .35s ease, background-color .2s ease, border-color .2s ease;

    i{
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
    }

  }

  .carousel-control-prev:hover .cc-prev-icon,
  .carousel-control-next:hover .cc-next-icon{
    background-color: rgba($color-brand-primary, .6);
    border-color: $color-brand-primary;
  }

  &:hover{
    .cc-prev-icon,
    .cc-next-icon{
      opacity: 1;
    }
  }

  .carousel-indicators .active{
    background-color: $color-brand-primary;
  }

  .carousel-caption{
    text-align: center;
    display: flex;
    justify-content: center;
    bottom: 35px;

    h3{
      font-size: 34px;
      line-height: 1;
      margin-bottom: 20px;
      text-shadow: 0px 0px 6px rgba(#000, .45);
    }
    p{
      max-width: 900px;
      margin: 0 auto;
      line-height: 1.1;
      text-shadow: 0px 0px 3px rgba(#000, .45);
    }

    &.text-black{
      h3, p{
        color: #000;
        text-shadow: 0px 0px 3px rgba(#fff, .1);
      }
    }
  }

}
