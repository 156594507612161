// COMPONENT: DATAGRID
// -----------------------------------------------------------------------------

.datagrid{
  padding: 20px;
}

// Datagrid Filters
.datagrid-filters{
  width: 100%;
  height: 50px;
  display: inline-flex;
  align-items: center;
}

// Datagrid Search
.datagrid-search{
  position: relative;
  height: 40px;
  margin-left: auto;

  .search-icon{
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    color: $color-silver-darkest;
  }

  input{
    width: 300px;
    border: none;
    border: 1px solid $color-silver;
    padding: 0 50px 0 20px;
    margin: 0;
    height: 40px;
    border-radius: 20px;
    line-height: 40px;
    font-size: 14px;
    color: $color-grey-light;
    background-color: $color-white;
    //transition: all .25s;
    outline: none;

    //&:valid{ width: 320px; }
    &:hover{ border-color: $color-silver-darkest; }
    &:focus{
      outline: none;
      box-shadow: none;
      border-color: $color-silver-darkest;
      //width: 300px;
    }

  }
}

// Datagrid Datepicker
.datagrid-datepicker{
  position: relative;
  height: 40px;
  margin-right: auto;

  .date-icon{
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    color: $color-silver-darkest;
  }

  input{
    width: 200px;
    border: none;
    border: 1px solid $color-silver;
    padding: 0 50px 0 20px;
    margin: 0;
    height: 40px;
    border-radius: 20px;
    line-height: 40px;
    font-size: 14px;
    color: $color-grey-light;
    background-color: $color-white;
    //transition: all .25s;
    outline: none;

    //&:valid{ width: 320px; }
    &:hover{ border-color: $color-silver-darkest; }
    &:focus{
      outline: none;
      box-shadow: none;
      border-color: $color-silver-darkest;
      //width: 300px;
    }

  }
}

.datagrid-header{
  width: 100%;
  position: relative;
  height: 50px;
  background-color: $theme-body-bg;
  margin-bottom: 2px;
  z-index: $zindex-dropdown + 1;

  &:before, &:after{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20px;
    background-color: $theme-body-bg;
  }
  &:before{ left: -20px; }
  &:after{ right: -20px; }

  table{
    margin: 0;
  }

  table thead tr th{
    border-top: none;
    border-bottom: 1px solid $color-silver;
    height: 50px;
  }
}

.datagrid-body{
  table{
    margin: 0;
    border: none;
    background: $color-white;
    border-bottom: 1px solid $color-silver;
    overflow: hidden;

    tbody{ box-shadow: 0 1px 2px rgba(black, .1); }
  }

  table thead tr th{
    border-top: none;
    border-bottom: 1px solid $color-silver;
    height: 50px;
    background-color: $theme-body-bg;
  }

  table tbody tr td{
    min-height: 50px;
    height: 50px;
    line-height: 15px;
    vertical-align: middle;
    border-top: 1px solid $color-silver-light;
  }

  table tbody tr:first-child td{ border-top: none; }

  table thead tr th:first-child,
  table tbody tr td:first-child{ padding-left: 20px; }
  table thead tr th:last-child,
  table tbody tr td:last-child{ padding-right: 20px; }

  .table-hover {
    tbody tr {
      @include hover {
        background-color: $color-silver-lighter;
      }
    }
  }

  .row-link td:not(.not-a-link):hover,
  .row-link td:not(.row-actions):hover,
  .row-modal-link td:not(.not-a-link):hover{
    cursor: pointer;
  }

  .badge{
    border-radius: 5px;
    margin-left: 5px;
    &:first-child{ margin: 0; }
  }
}

.datagrid-sticky{
  .datagrid-header{
    position: sticky;
    top: 0;
  }
}


// ROWS LINKS & ACTIONS
.row-actions{
  width: 60px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  cursor: default;
  .dropdown{
    .dropdown-menu{
      padding: 0;
      border-color: $color-black-darker;
      background-color: $color-black;
      border: none;
      border-radius: 3px;
      box-shadow: 0 1px 2px rgba(black, .05);
      overflow: hidden;

      .dropdown-item{
        height: 36px;
        padding: 0 15px;
        line-height: 36px;
        color: $color-silver;
      }
      .dropdown-item:hover{
        //background-color: rgba($color-white, .1);
        background-color: $color-blue;
        color: $color-white;
      }
    }
  }
  .dropdown.show > button{
    color: $color-blue;
  }
  .dropdown > button{
    width: 30px;
    height: 30px;
    border: none;
    outline: none;
    background: none;
    margin: 0;
    padding: 0;
    border-radius: 5px;
    transition: all .2s;

    &:hover{
      background-color: $color-blue;
      border-radius: 15px;
      color: $color-white;
    }

    i{
      width: 30px;
      text-align: center;
    }
  }
}








// SORTABLE
a.sortable{
  text-decoration: none;
  color: $color-grey;
  font-weight: 400;

  span{ margin-left: 10px; }
  span i{ @extend .fa-sort; }

  &:hover{ color: $color-blue; }
  &.sort-asc span i{ @extend .fa-sort-up; }
  &.sort-desc span i{ @extend .fa-sort-down; }
}




/*
.row-label{
  width: 18px;
  height: 18px;
  display: inline-block;
  background-color: $color-grey;
  color: #fff;
  border-radius: 9px;
  text-align: center;
  line-height: 19px;
  font-size: 10px;
  margin-left: 8px;

  &.success{ background-color: $color-green; }
  &.warning{ background-color: $color-orange; }
}

.row-status{
  i{ color: $color-grey; }
  &.active i{ color: $color-green; }
}

.row-badge{
  margin-right: 5px;
}
*/
