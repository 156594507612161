// VIEW: AUTHENTICATION
// -----------------------------------------------------------------------------

.authentication {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: $color-silver;
}

.auth-block {
  width: 100%;
  max-width: 360px;
  padding: 20px;
  margin: 0 auto;
  background-color: $color-white;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(black, .1);
}

.auth-block{

  .brand{
    padding: 40px 50px;
    svg{
      width: 100%;
    }
  }

  h2{
    text-align: center;
    font-size: 20px;
    line-height: 20px;
    color: $color-black;
    margin-bottom: 20px;
    font-weight: 400;
  }

  form{}

  input{
    box-shadow: none !important;
    height: 44px;
    font-size: 16px;

    border-radius: 3px;
  }

  .form-group{
    position: relative;
    margin-bottom: 20px;
  }

  .auth-forgot{
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 15px;
    line-height: 44px;
  }
  .input-group{}

  .custom-checkbox{

    padding-bottom: 20px;
    color: $color-grey-lightest;

  }

  .btn{
    border-radius: 3px;
  }

}
