// COLOURS
// -----------------------------------------------------------------------------

$color-white:                   #ffffff;

// $color-silver:                  #e3e6e9;
// $color-grey:                    #656b70;
// $color-black:                   #282c34;
// $color-green:                   #28c76f;
// $color-teal:                    #5dbeb2;
// $color-blue:                    #468de0;
// $color-purple:                  #6d5fe7;
// $color-red:                     #bf0007;
// $color-orange:                  #ff7819;
// $color-yellow:                  #ffba58;

$color-silver:                  #e3e6e9;
$color-grey:                    #656b70;
$color-black:                   #282c34;
$color-green:                   #009B3E;
$color-teal:                    #009788;
$color-blue:                    #2196f3;
$color-cyan:                    #1ebef0;
$color-purple:                  #673bb7;
$color-indigo:                  #3f51b5;
$color-red:                     #ea243f;
$color-pink:                    #ea1e63;
$color-orange:                  #fe5722;
$color-yellow:                  #ffd438;

$color-lightest:                9%;
$color-lighter:                 6%;
$color-light:                   3%;
$color-dark:                    4%;
$color-darker:                  8%;
$color-darkest:                 12%;


// Colours Blue
$color-blue-lightest:           lighten($color-blue, $color-lightest);
$color-blue-lighter:            lighten($color-blue, $color-lighter);
$color-blue-light:              lighten($color-blue, $color-light);
$color-blue-dark:               darken($color-blue, $color-dark);
$color-blue-darker:             darken($color-blue, $color-darker);
$color-blue-darkest:            darken($color-blue, $color-darkest);

// Colours Cyan
$color-cyan-lightest:           lighten($color-cyan, $color-lightest);
$color-cyan-lighter:            lighten($color-cyan, $color-lighter);
$color-cyan-light:              lighten($color-cyan, $color-light);
$color-cyan-dark:               darken($color-cyan, $color-dark);
$color-cyan-darker:             darken($color-cyan, $color-darker);
$color-cyan-darkest:            darken($color-cyan, $color-darkest);

// Colours Silver
$color-silver-lightest:         lighten($color-silver, $color-lightest);
$color-silver-lighter:          lighten($color-silver, $color-lighter);
$color-silver-light:            lighten($color-silver, $color-light);
$color-silver-dark:             darken($color-silver, $color-dark);
$color-silver-darker:           darken($color-silver, $color-darker);
$color-silver-darkest:          darken($color-silver, $color-darkest);

// Colours Grey
$color-grey-lightest:           lighten($color-grey, $color-lightest);
$color-grey-lighter:            lighten($color-grey, $color-lighter);
$color-grey-light:              lighten($color-grey, $color-light);
$color-grey-dark:               darken($color-grey, $color-dark);
$color-grey-darker:             darken($color-grey, $color-darker);
$color-grey-darkest:            darken($color-grey, $color-darkest);

// Colours Black
$color-black-lightest:          lighten($color-black, $color-lightest);
$color-black-lighter:           lighten($color-black, $color-lighter);
$color-black-light:             lighten($color-black, $color-light);
$color-black-dark:              darken($color-black, $color-dark);
$color-black-darker:            darken($color-black, $color-darker);
$color-black-darkest:           darken($color-black, $color-darkest);

// Colours Green
$color-green-lightest:          lighten($color-green, $color-lightest);
$color-green-lighter:           lighten($color-green, $color-lighter);
$color-green-light:             lighten($color-green, $color-light);
$color-green-dark:              darken($color-green, $color-dark);
$color-green-darker:            darken($color-green, $color-darker);
$color-green-darkest:           darken($color-green, $color-darkest);

// Colours Teal
$color-teal-lightest:           lighten($color-teal, $color-lightest);
$color-teal-lighter:            lighten($color-teal, $color-lighter);
$color-teal-light:              lighten($color-teal, $color-light);
$color-teal-dark:               darken($color-teal, $color-dark);
$color-teal-darker:             darken($color-teal, $color-darker);
$color-teal-darkest:            darken($color-teal, $color-darkest);

// Colours Yellow
$color-yellow-lightest:         lighten($color-yellow, $color-lightest);
$color-yellow-lighter:          lighten($color-yellow, $color-lighter);
$color-yellow-light:            lighten($color-yellow, $color-light);
$color-yellow-dark:             darken($color-yellow, $color-dark);
$color-yellow-darker:           darken($color-yellow, $color-darker);
$color-yellow-darkest:          darken($color-yellow, $color-darkest);

// Colours Pink
$color-pink-lightest:           lighten($color-pink, $color-lightest);
$color-pink-lighter:            lighten($color-pink, $color-lighter);
$color-pink-light:              lighten($color-pink, $color-light);
$color-pink-dark:               darken($color-pink, $color-dark);
$color-pink-darker:             darken($color-pink, $color-darker);
$color-pink-darkest:            darken($color-pink, $color-darkest);

// Colours Red
$color-red-lightest:            lighten($color-red, $color-lightest);
$color-red-lighter:             lighten($color-red, $color-lighter);
$color-red-light:               lighten($color-red, $color-light);
$color-red-dark:                darken($color-red, $color-dark);
$color-red-darker:              darken($color-red, $color-darker);
$color-red-darkest:             darken($color-red, $color-darkest);

// Colours Orange
$color-orange-lightest:         lighten($color-orange, $color-lightest);
$color-orange-lighter:          lighten($color-orange, $color-lighter);
$color-orange-light:            lighten($color-orange, $color-light);
$color-orange-dark:             darken($color-orange, $color-dark);
$color-orange-darker:           darken($color-orange, $color-darker);
$color-orange-darkest:          darken($color-orange, $color-darkest);

// Colours Purple
$color-purple-lightest:         lighten($color-purple, $color-lightest);
$color-purple-lighter:          lighten($color-purple, $color-lighter);
$color-purple-light:            lighten($color-purple, $color-light);
$color-purple-dark:             darken($color-purple, $color-dark);
$color-purple-darker:           darken($color-purple, $color-darker);
$color-purple-darkest:          darken($color-purple, $color-darkest);

// Colours Indigo
$color-indigo-lightest:         lighten($color-indigo, $color-lightest);
$color-indigo-lighter:          lighten($color-indigo, $color-lighter);
$color-indigo-light:            lighten($color-indigo, $color-light);
$color-indigo-dark:             darken($color-indigo, $color-dark);
$color-indigo-darker:           darken($color-indigo, $color-darker);
$color-indigo-darkest:          darken($color-indigo, $color-darkest);
