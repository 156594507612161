/**
mbc_icons Webfont
*/
@font-face {
 font-family: 'mbc_icons';
 src: url('../../fonts/mbc/mbc_icons.eot?1564122194613');
 src: url('../../fonts/mbc/mbc_icons.eot?#iefix-1564122194613') format('embedded-opentype'),
  url('../../fonts/mbc/mbc_icons.woff2?1564122194613') format('woff2'),
  url('../../fonts/mbc/mbc_icons.woff?1564122194613') format('woff'),
  url('../../fonts/mbc/mbc_icons.ttf?1564122194613') format('truetype');
 font-weight: normal;
 font-style: normal;
}


.mbc-icon{
  display: inline-block;
  font: normal normal normal 16px/1 mbc_icons;
  text-rendering: auto;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Sizes */
.mbc-icon-sm {
  font-size: 10px;
}
.mbc-icon-md {
  font-size: 22px;
}
.mbc-icon-2x {
  font-size: 32px;
}
.mbc-icon-lg {
  font-size: 64px;
}

.mbc-icon-inverse {
  color: #ffffff;
}

/* Animations */
.mbc-icon-spin {
  -webkit-animation: gi-spin 2s infinite linear;
  animation: gi-spin 2s infinite linear;
}
@-webkit-keyframes gi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes gi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.mbc-icon-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.mbc-icon-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.mbc-icon-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.mbc-icon-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.mbc-icon-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
:root .mbc-icon-rotate-90,
:root .mbc-icon-rotate-180,
:root .mbc-icon-rotate-270,
:root .mbc-icon-flip-horizontal,
:root .mbc-icon-flip-vertical {
  filter: none;
}


/* Glyphs list */

.mbc-icon-crustaceans:before {
 content: '\ea01';
}

.mbc-icon-fish:before {
 content: '\ea02';
}

.mbc-icon-gluten:before {
 content: '\ea03';
}

.mbc-icon-milk:before {
 content: '\ea04';
}

.mbc-icon-nuts:before {
 content: '\ea05';
}

.mbc-icon-poi-eye:before {
 content: '\ea06';
}

.mbc-icon-poi-photo:before {
 content: '\ea07';
}

.mbc-icon-soya:before {
 content: '\ea08';
}
