// GLOBAL CONFIG & VARIABLES
// -----------------------------------------------------------------------------

@import "colours";

// SIZES
// -----------------------------------------------------------------------------

$sidebar-width:               260px;
$topbar-height:               64px;


// MISC
// -----------------------------------------------------------------------------
$responsive-breakpoint:       "xl";


// THEME COLOURS
// -----------------------------------------------------------------------------

// Brand Colours
$color-brand-primary:         #009B3E;
$color-brand-secondary:       #323948;

// UI Colours
$theme-body-bg:               $color-silver-light;
$theme-topbar-bg:             $color-indigo;
$theme-main-color:            $color-indigo;
