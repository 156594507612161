// COMPONENT: FORM TOGGLE LABELS
// -------------------------------------------------------------------
.control-label-switch{
  position: relative;
  display: block;
  width: 100%;
  border: 1px solid $color-silver-darker;
  height: 35px;
  border-radius: 2px;
}
.toggle-labels{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 33px;
  background: $color-silver-lighter;

  label{
    display: block;
    width: 100%;

    .form-switch-label{
      position: relative;
      float: left;
      width: 50%;
      height: 33px;
      text-align: center;
      color: darken($color-silver-darkest, 10%);
      z-index: 6;
      transition: color .2s;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 33px;

      i{
        position: absolute;
        top: 0;
        left: 0;
        width: 33px;
        height: 33px;
        text-align: center;
        line-height: 33px;
        opacity: 0;
        transition: opacity .4s;
      }
      span{
        display: inline-block;
        line-height: 33px;
        font-weight: 400;
        font-size: 14px;
      }
      small{
        padding-left: 10px;
        font-size: 12px;
        line-height: 33px;
        font-weight: 300;
      }
      &:hover{ color: $color-grey-darkest; }
    }
    .fsl-l{
      color: $color-grey-darkest;
      span{ font-weight: 600; }
      i{ opacity: 1; }
    }
  }

  .real-toggle, .fake-toggle{
    position: absolute;
    top: 0;
    left: 0;
  }

  .real-toggle{
    width: 100%;
    height: 33px;
    margin: 0px;
    padding: 0px;
    opacity: 0;
    z-index: 3;
  }

  .fake-toggle{
    width: 100%;
    height: 33px;
    margin: 0;
    z-index: 2;

    &:after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background: #fff;
      z-index: 1;
      border-radius: 1px 0 0 1px;
      width: 50%;
      height: 33px;
      transition: all .3s ease-in-out;

    }
  }

  .real-toggle:checked + .fake-toggle{
    &:after{
      border-radius: 0 1px 1px 0;
      left: 50%;
    }
  }

  .real-toggle:checked + .fake-toggle + .fsl-l{
    color: darken($color-silver-darkest, 10%);
    span{ font-weight: 400; }
    i{ opacity: 0; }
    &:hover{
      color: $color-grey-darkest;
    }
  }

  .real-toggle:checked + .fake-toggle + .fsl-l + .fsl-r{
    color: $color-grey-darkest;
    span{ font-weight: 600; }
    i{ opacity: 1; }
  }

}


// COMPONENT: ICON CHECKBOX
// -------------------------------------------------------------------
.control-icon-checkbox{
  position: relative;
  display: block;
  margin-right: 0;

  width: 38px;
  height: 38px;
  border-radius: 3px;

  label{
    position: relative;
    display: block;
    width: 38px;
    height: 38px;
    margin: 0;
  }

  .real-toggle, .fake-toggle{
    position: absolute;
    top: 0;
    left: 0;
  }

  .real-toggle{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    opacity: 0;
    z-index: 3;
  }

  .fake-toggle{
    margin: 0;
    z-index: 2;
    background: #fff;
    border: 1px solid $color-silver-darkest;
    border-radius: 3px;
    width: 38px;
    height: 38px;
    transition: all .3s ease-in-out;
    text-align: center;
    line-height: 38px;

    i{
      float: left;
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
    }

    span{
      font-size: 14px;
      font-weight: 400;
    }
  }

  .real-toggle:checked + .fake-toggle{
    background-color: $color-brand-primary;
    border-color: darken($color-brand-primary, 7%);

    i, span{
      color: $color-white;
    }
  }

}

.control-icon-checkbox-group{
  display: block;
  width: 100%;
  float: left;
  margin-bottom: -10px;
  .control-icon-checkbox{
    position: relative;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    float: left;
  }
}
