// Form Labels
.form-group{
  > label{
    font-size: 13px;
    color: $color-grey;
  }
}


form label{
  font-size: 13px;
  color: $color-grey;
}

// Custom Checkboxes & Radios
.form-group{
  .custom-control.custom-checkbox,
  .custom-control.custom-radio{
    padding-top: 5px;
  }
}

.custom-control.custom-checkbox {
  padding-top: 5px;
  .custom-control-label::before {
    border-radius: 3px;
  }
}

// Custom Range
.custom-range {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    border-radius: 1rem;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  &::-webkit-slider-runnable-track {
    border-radius: 1rem;
  }

  &::-moz-range-thumb {
    border-radius: 1rem;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  &::-moz-range-track {
    border-radius: 1rem;
  }

  &::-ms-thumb {
    border-radius: 1rem;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  &::-ms-fill-lower {
    border-radius: 1rem;
  }

  &::-ms-fill-upper {
    border-radius: 1rem;
  }

}

// Form Select
select,
.custom-select {
  display: inline-block;
  width: 100%;
  height: $custom-select-height;
  padding: $custom-select-padding-y ($custom-select-padding-x + $custom-select-indicator-padding) $custom-select-padding-y $custom-select-padding-x;
  font-family: $custom-select-font-family;
  @include font-size($custom-select-font-size);
  font-weight: $custom-select-font-weight;
  line-height: $custom-select-line-height;
  color: $custom-select-color;
  vertical-align: middle;
  background: $custom-select-background;
  background-color: $custom-select-bg;
  border: $custom-select-border-width solid $custom-select-border-color;
  @include border-radius($custom-select-border-radius, 0);
  @include box-shadow($custom-select-box-shadow);
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;


  &:focus {
    border-color: $color-grey;
    outline: 0;

    &::-ms-value {
      color: $input-color;
      background-color: $input-bg;
    }
  }

  &[multiple],
  &[size]:not([size="1"]) {
    height: auto;
    padding-right: $custom-select-padding-x;
    background-image: none;
  }

  &:disabled {
    color: $custom-select-disabled-color;
    background-color: $custom-select-disabled-bg;
  }

  // Hides the default caret in IE11
  &::-ms-expand {
    display: none;
  }
}
