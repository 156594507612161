.excursions-block,
.experiences-block{
  background-color: $color-silver-light;
}

.card-columns {
  @include media-breakpoint-only(sm) {
    column-count: 1;
  }
  @include media-breakpoint-only(md) {
    column-count: 2;
  }
}

.excursions-list{
  margin-top: -40px;
  .excursion-item{
    position: relative;
    overflow: hidden;
    border-radius: 4px;



    .soldout-label{
      position: absolute;
      top: 10px;
      left: 10px;

      span{
        color: $color-black;
        background-color: rgba($color-yellow-light, .9);
        padding: 6px 8px;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .card-img-top{
      overflow: hidden;
      border-radius: 4px 4px 0 0;
      height: 210px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: no-repeat center center scroll;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;

      .card-icon{
        position: relative;
        display: block;
        width: 60px;
        height: 60px;
        background: rgba($color-brand-primary, .75);
        border-radius: 30px;
        border: 1px solid rgba($color-brand-primary, .85);
        text-align: center;
        opacity: 0;
        margin-top: 50%;
        transition: opacity .25s ease, margin-top .25s ease;

        i{
          color: #fff;
          width: 60px;
          height: 60px;
          line-height: 60px;
          text-align: center;
        }
      }

      &:hover .card-icon{
        opacity: 1;
        margin-top: 0;
      }
    }

    .exc-desc{
      height: 60px;
      margin-bottom: 20px;

      h6 {
          font-size: 16px;
          font-weight: 600;
          margin: 0;
        }
      p{
        font-size: 14px;
        line-height: 15px;
        font-weight: 300;
        color: $color-grey-lightest;
      }
    }

    .exc-actions{
      a{
        display: inline-block;
        background: $color-silver-light;
        border-radius: 4px;
        width: 36px;
        height: 36px;
        margin-right: 10px;
        text-align: center;
        line-height: 36px;
        color: $color-grey-dark;

        &:hover{
          background: $color-green;
          color: $color-white;
        }
      }

    }

    .card-dates{
      position: absolute;
      top: 102%;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba($color-black-dark, .85);
      border-radius: 4px;
      transition: top .4s ease;

      .card-date-header{
        width: 100%;
        height: 44px;
        color: $color-silver;

        h2{
          font-size: 17px;
          float: left;
          line-height: 44px;
          margin-left: 15px;
        }

        span.btn-close-dates{
          float: right;
          line-height: 44px;
          margin-right: 0px;
          width: 44px;
          height: 44px;
          text-align: center;
          outline: none;
          &:focus{outline:none;}
          &:hover{
            color: $color-red;
          }
        }
      }
      .card-date-body{
        border-top: 1px solid rgba(#fff, .1);
        position: absolute;
        top: 44px;
        left: 0px;
        right: 0px;
        padding-right: 15px;
        padding-left: 15px;
        bottom: 0px;
        overflow: auto;
        .booking-dates ul li:first-child{
          margin-top: 15px;
        }
        .booking-dates ul li:last-child{
          margin-bottom: 15px;
        }
      }
    }

    &.dates-is-active{
      .card-dates{
        top: 0;
      }
    }

    &.item-soldout{
      .card-img-top,
      .card-body{
        opacity: .6;
      }
    }
  }
}

.excursion-price{
  position: absolute;
  width: 100%;
  top: 30%;
  left: 0;

  .price-badge{
    float: right;
    padding: 10px 16px 12px 16px;
    background-color: $color-red;
    color: $color-white;
    border-radius: 6px;
    small{ line-height: 1; }
    h1{
      margin: 0;
      line-height: 1;
    }
  }
}

.excursion-remaining{
  position: relative;
  width: 100%;
  @include clearfix();
  margin-bottom: 20px;
  .circle-p{ float: left; }
  .circle-percentage{
    position: absolute;
    top: 0;
    left: 4px;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
  }
}

.excursions-filters{
  margin-top: -40px;

  .card{
    background-color: $color-white;
    box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075) !important;
  }

  .card-header{
    background-color: darken($color-brand-primary, 7%);
    color: $color-white;
    font-size: 16px;
    font-weight: 400;
  }

  .card-body{

  }

  // Voucher

  .card-voucher {
    border: none;
    background-color: transparent;

    .card-body {
      padding: 0;
    }

    .voucher-link {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none;
      color: #fff;
      padding: 20px;
      background-color: $color-brand-primary;
      border: none;
      border-radius: 5px;

      &:hover {
        background-color: lighten($color-brand-primary, 5%);
      }
    }

    span {
      display: inline-block;
      font-size: 13px;
      line-height: 13px;
    }

    i {
      font-size: 36px;
    }

    .text {
      margin-right: 20px;
    }

    .icon {
      margin-left: auto;
    }
  }

  .filter-scroll{
    width: 100%;
    overflow: auto;
    max-height: 145px;
  }

  .filter-buttons{
    margin-top: 8px;
    margin-bottom: -8px;

    button.filter-item{
      float: left;
      display: inline;
      outline: none;
      border: 1px solid $color-silver-darkest;
      background-color: $color-silver-lightest;
      margin-right: 8px;
      margin-bottom: 8px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 400;
      color: $color-grey-dark;
      padding: 4px 8px;

      &.active{
        background-color: $color-brand-primary;
        border-color: darken($color-brand-primary, 5%);
        color: $color-white;
      }
    }
  }

  .filter-mobility{

    .mobility-actions{
      a{
        display: inline-block;
        background: $color-silver-light;
        border-radius: 4px;
        width: 36px;
        height: 36px;
        margin-right: 10px;
        text-align: center;
        line-height: 36px;
        color: $color-grey-dark;

        &.btn-all{
          width: auto;
          padding-left: 10px;
          padding-right: 10px;
          text-decoration: none;
        }

        &:hover{
          background: $color-blue;
          color: $color-white;
        }
      }
    }
  }
}

.excursions-popover{

  .popover-header{

  }

  .popover-body{
    overflow: auto;
    width: 240px;
    min-height: 70px;
    max-height: 185px;
    padding: 0;

    ul{
      list-style: none;
      margin: 0;
      padding: 15px;

      li{
        width: 100%;
        margin-top: 10px;

        &:first-child{ margin: 0; }
      }

      li a{
        display: block;
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        background-color: $color-brand-primary;
        color: #fff;
        border-radius: 3px;
        font-size: 13px;

        text-decoration: none;
      }
    }
  }
}

// EXCURSIONS PAGE

.excursion-selected{
  small{
    color: $color-grey;
  }
  .excursion-item{
    display: block;
    width: 100%;
    padding: 20px 10px 20px 20px;
    background-color: $color-brand-primary;
    color: #fff;
    overflow: hidden;
    border-radius: 4px;

    .ei-title{
      float: left;
      display: block;
      height: 40px;
      h4{
        font-size: 17px;
        text-transform: uppercase;
        margin: 0;
      }
      span{
        font-size: 14px;
      }
    }

    .ei-options{
      float: right;

      .ei-icon{
        float: left;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
      }
    }
  }
}

.excursion-config{
  margin-top: 30px;

  small{
    color: $color-grey;
  }

  .user-management{


    .user-list{
      margin: 0;
      padding: 0;
      margin-bottom: 20px;
      list-style: none;

      .user-item{
        position: relative;
        min-height: 40px;
        margin-top: 5px;
        background-color: $color-silver-lighter;
        border: 1px solid $color-silver;
        //color: #fff;
        border-radius: 4px;

        &:first-child{ margin: 0; }

        .user-form{
          margin-right: 60px;
          padding: 10px 20px;


          .form-group{

            label{
              color: $color-grey-lightest;
            }
          }

          .allergy-group i{
            font-size: 22px;
          }
        }




        .user-delete{
          position: absolute;
          right: 10px;
          top: 50%;
          margin-top: -20px;
          width: 40px;
          height: 40px;

          button{
            width: 40px;
            height: 40px;
            outline: none;
            margin: 0;
            padding: 0;
            background: none;
            border: none;
            display: block;
            text-align: center;
            line-height: 40px;
            color: $color-grey;
            &:hover{
              color: $color-red;
            }

            i{
              width: 40px;
              height: 40px;
              text-align: center;
              line-height: 40px;
            }
          }
        }
      }

    }

    .user-add{

    }


  }

}


.booking-dates{

  ul{
    list-style: none;
    margin: 0;
    padding: 0;

    li{
      position: relative;
      float: left;

      width: 100%;
      margin-top: 10px;
      display: inline-block;
      &:first-child{ margin: 0; }
    }

    li a, li .soldout-wrapper{
      overflow: hidden;
      display: block;
      //height: 30px;
      padding: 20px 10px;
      background-color: $color-silver-lighter;
      border: 1px solid $color-silver;
      color: $color-grey;
      border-radius: 3px;
      text-decoration: none;
    }
    li a span, li .soldout-wrapper > span{
      display: inline-block;
      float: left;
      //height: 30px;
      font-size: 14px;
      line-height: 15px;
      width: 60%;
      small{
        font-weight: 500;
        font-size: 15px;
      }
    }

    li a .exc-remaining, li .soldout-wrapper .exc-remaining{
      position: relative;
      float: right;

      height: 30px;


      .circle-percentage{

        height: 30px;
        line-height: 11px;
        margin-right: 36px;
        font-weight: 500;
        font-size: 15px;
        text-align: right;
        padding-top: 5px;

        small{
          font-size: 12px;
        }
      }
    }

    li a .circle-p, li .soldout-wrapper .circle-p{
      position: absolute;
      top: 0;
      right: 0;
    }

    li a:hover{
      background-color: $color-silver-light;
      border: 1px solid $color-silver-dark;
    }


    li.item-soldout{
      .exc-remaining, .soldout-wrapper > span{
        opacity: .5;
      }
      .soldout-wrapper .date-soldout-label{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($color-yellow-lighter, .7);
        border-radius: 3px;
        span{
          font-weight: 600;
          color: #000;
        }
      }
    }
  }
}

.experience-item{
  .booking-dates{
    display: block;
    width: 100%;
    @include clearfix();
    margin-top: 20px;
  }
  .list-group-item{
    padding-left: 10px;
    padding-right: 10px;
  }
  .menu-name{
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    margin-right: 20px;
  }
  .card-body > h4{
    margin-right: 40px;
  }
  .menu-details{
    margin-left: auto;
    display: flex;
    align-items: flex-end;
    .btn-pdf{
      color: $color-grey;
      text-decoration: none;
      display: inline-block;
      width: 32px;
      height: 18px;
      text-align: center;
      i{
        font-size: 18px;
        line-height: 18px;
        float: left;
        width: 32px;
        text-align: center;
      }
      &:hover{
        color: $color-brand-primary;
      }
    }
    span{
      display: inline-block;
      font-size: 14px;
      //width: 60%;
      small{
        font-size: 15px;
        font-weight: 600;
      }
    }
  }

  h6{
    font-size: 14px;
    color: $color-grey-lightest;
  }
}
