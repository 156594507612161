// COMPONENT: BUTTONS
// -----------------------------------------------------------------------------

.btn.btn-icon{
  padding: 0;
  margin-left: 20px;
  width: 40px;
  height: 40px;
  line-height: 39px;
  text-align: center;
  border-radius: 20px;
  i{ margin: 0; }
}

.btn-cart{
  position: relative;
  .badge{
    position: absolute;
    top: 2px;
    right: -6px;
    font-size: 10px;
  }
}


.dashed-row.add-button{
  box-shadow: none;
  margin-top: 10px;

  .btn-add-item{
    display: block;
    width: 100%;
    text-align: center;
    border: 1px dashed $color-silver-darker;
    border-radius: 3px;
    padding: 16px;
    color: $color-grey-lighter;
    text-decoration: none;
    transition: background .2s;

    &:hover{
      background: $color-silver-dark;
      color: $color-black-lighter;
      border: 1px dashed darken($color-silver-darkest, 3%);
    }

    i{}

    span{
      display: block;
      width: 100%;
      text-align: center;
      font-size: 16px;
    }
  }
}
