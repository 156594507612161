// COMPONENT: UTILITIES
// -----------------------------------------------------------------------------


.hide{ display: none; }

.in-development-block{
  text-align: center;
  padding: 15% 0;
  i{
    font-size: 84px;
    opacity: .15;
  }

  span{
    width: 100%;
    display: inline-block;
    font-size: 14px;
    margin-top: 10px;
    font-weight: 400;
    text-transform: uppercase;
    color: darken($color-silver-darkest, 3%);
  }
}
