// LAYOUT: MAIN
// -----------------------------------------------------------------------------

html,
body {
  //height: 100%;
  //background-color: $color-silver-lightest;
}

body{
  //background-color: $theme-body-bg;
  font-weight: 300;
  color: $color-grey;
}

body > main{
  //width: 100%;
  //height: 100%;
  //overflow: hidden;
}

.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
 }

 // MAIN HEADER
 // -----------------------------------------------------------------------------
 header{ position: relative; }



// CONTENT CONTAINER
// -----------------------------------------------------------------------------
.content{}

// NEWSLETTER
// -----------------------------------------------------------------------------
.newsletter{
  position: relative;
  background-color: $gray-800;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-top: 7rem;
  padding-bottom: 7rem;
  .overlay {
    position: absolute;
    background-color: $gray-900;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.3;
  }
  input{
    font-weight: 300;
  }
}


// RESPONSIVE
// -----------------------------------------------------------------------------
@include media-breakpoint-up($responsive-breakpoint) {

}
