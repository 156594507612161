footer.footer {
  padding-top: 4rem;
  padding-bottom: 4rem;
  h5{
    font-size: 16px;
    margin-bottom: 2rem;
  }

  .footer-info{
    margin-bottom: 25px;
    h5{
      font-size: 16px;
      margin-bottom: 10px;
    }

    p{
      font-size: 14px;
      font-weight: 500;
    }

    @include media-breakpoint-down(md) {
      text-align: center;
    }
    
  }
}
