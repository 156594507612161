// COMPONENT: MISC
// -----------------------------------------------------------------------------

// Status Icons & Label
.status-label{
  text-transform: capitalize;

  i{
    color: $color-grey-lightest;
    margin-right: 6px;
  }

  &.archived,
  &.disabled,
  &.inactive,
  &.pending{
    i{ color: $color-grey; }
  }

  &.draft{
    i{ color: $color-red-lightest; }
  }

  &.enabled,
  &.active,
  &.fulfill{
    i{ color: $color-green; }
  }

}

// Tags Group
.tags-group{
  max-width: 260px;
  span{
    display: inline-block;
    margin-right: 4px;
    margin-top: 2px;
    margin-bottom: 2px;
    height: 18px;
    line-height: 18px;
    padding: 0 10px;
    border-radius: 9px;
    background-color: $color-grey;
    color: $color-white;
    font-size: 12px;
  }

  &.tags-full-width{
    max-width: 100%;
  }

  &.tags-yellow span{
    background-color: $color-yellow-lightest;
    color: $color-grey-dark;
  }

  &.tags-blue span{
    background-color: $color-blue;
    color: $color-white;
  }
}

// Flatpickr

.flatpickr-input[readonly]{
  background-color: $color-white;
  &.active{
    background-color: $color-white;
  }
}

// Crop Text

.crop-text-1 {
  -webkit-line-clamp: 1;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.crop-text-2 {
  -webkit-line-clamp: 2;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.crop-text-3 {
  -webkit-line-clamp: 3;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}


.voucher-excursions {
  .excursions-list {
    margin: 0;

    .exc-desc {
      margin: 0 !important;
    }
  }
}
