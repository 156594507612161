.static-header{
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 0;

  .sh-container{
    width: 100%;
    height: 400px;
    background-color: rgba(#000, .35);
  }

  h1{
    color: $color-white;
    margin-top: 40px;
    text-shadow: 0 1px 2px rgba(0,0,0,0.6);
    font-size: 50px;
  }
}
